.main-container {
  display: flex;
}

.main-content {
  position: relative;
  // overflow-x: auto;
  width: 100%;
  height: 100vh;
  // background-color: #f2f2f2;
}
